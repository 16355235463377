import * as React  from "react";
import { Routes, Route, Outlet, Link, } from "react-router-dom";
import { Button, Box, AppBar, Toolbar, Typography,} from '@mui/material';
import HomePage from './Pages/Home/Main'
import Contact from "./Pages/Contact/contact"
import Gallery from './Pages/Gallery/Gallery'
import ProjectA from './Pages/Gallery/ProjectA'
import ProjectB from './Pages/Gallery/ProjectB';
import ProjectC from './Pages/Gallery/ProjectC';
import About from './Pages/About/About'
import Logo from "./Assets/IMAGES/Logo/LogoWhite.png"
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ADU from './Pages/ListofServices/ADU';
import ASBUILT from './Pages/ListofServices/ASBUILT';
import KITCHENREMODEL from './Pages/ListofServices/KITCHENREMODEL';
import MULTISTORYDUPLEX from './Pages/ListofServices/MULTISTORYDUPLEX';
import NEWHOME from './Pages/ListofServices/NEWHOME';
import PERMITSERVICES from './Pages/ListofServices/PERMITSERVICES';
import REPAIR from './Pages/ListofServices/REPAIR';
import ROOMADITION from './Pages/ListofServices/ROOMADITION';
import TENANTIMPROVEMENT from './Pages/ListofServices/TENANTIMPROVEMENT';
import CssBaseline from '@mui/material/CssBaseline';



export default function App() {
  return (
    <div>
    <Routes >
        <Route path="/" element={<Layout /> } >
          <Route index element={<HomePage />} />
          {/** SERVICES */}
          <Route path="ADU" element={<ADU />} />
          <Route path="ASBUILT" element={<ASBUILT />} />
          <Route path="KITCHENREMODEL" element={<KITCHENREMODEL />} />
          <Route path="MULTISTORYDUPLEX" element={<MULTISTORYDUPLEX />} />
          <Route path="NEWHOME" element={<NEWHOME />} />
          <Route path="PERMITSERVICES" element={<PERMITSERVICES />} />
          <Route path="REPAIR" element={<REPAIR />} />
          <Route path="ROOMADITION" element={<ROOMADITION />} />
          <Route path="TENANTIMPROVEMENT" element={<TENANTIMPROVEMENT />} />
          {/** END SERVICES */}
          <Route path="Gallery" element={<Gallery/>} />
          <Route path="About" element={<About/>} />
          <Route path="projecta" element={<ProjectA/>} />
          <Route path="projectb" element={<ProjectB/>} />
          <Route path="projectc" element={<ProjectC/>} />
          <Route path="Contact" element={<Contact/>} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline /> 
      <AppBar position="static" sx={{ bgcolor: "#003478" }}>
      <Toolbar>
          
        {/**This is where the Mobile Nave bar goes*/}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            
              <Link  to="/"  style={{ textDecoration: 'none', display: 'block',  }} >
              <Button color="inherit"  >Home</Button>
              </Link>
              <Link to=" " style={{ textDecoration: 'none', display: 'block',  }}> 
              <Button
              color="inherit"
              aria-controls={open ? 'Services' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Services
            </Button>
            </Link>
            <Menu
              id="Services"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Link  to="/ADU" style={{ textDecoration: 'none', display: 'block'}}>
              <MenuItem onClick={handleClose}>ADU</MenuItem>
              </Link>
              <Link to="/ASBUILT" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>AS BUILT</MenuItem>
              </Link>
              <Link to="/KITCHENREMODEL" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>KITCHEN REMODEL</MenuItem>
              </Link>
              <Link to="/MULTISTORYDUPLEX" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>MULTI STORY DUPLEX</MenuItem>
              </Link>
              <Link to="/NEWHOME" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>NEW HOME</MenuItem>
              </Link>
              <Link to="/PERMITSERVICES" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>PERMIT SERVICES</MenuItem>
              </Link>
              <Link to="/REPAIR" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>FIRE DAMAGE REPAIRS</MenuItem>
              </Link>
              <Link to="/ROOMADITION" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>ROOM ADDITION</MenuItem>
              </Link>
              <Link to="/TENANTIMPROVEMENT" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>TENANT IMPROVEMENT</MenuItem> 
              </Link>
            </Menu>

            {/**END*/}
            <Link to="/Gallery" style={{ textDecoration: 'none', display: 'block' }}>
            <Button color="inherit" >Gallery</Button>
            </Link>
            <Link to="/About" style={{ textDecoration: 'none', display: 'block' }}>
            <Button color="inherit" >About UDS</Button>
            </Link>
            <Link to="/Contact" style={{ textDecoration: 'none', display: 'block' }}>
            <Button color="inherit" >Contact US</Button>
            </Link>
            </Menu>
          </Box>
        {/**The end */}

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={Logo} alt="Logo" width="200" />
          </Typography>
          
          <Box  sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button color="inherit" href="/">Home</Button>
            <Button
              color="inherit"
              aria-controls={open ? 'Services' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Services
            </Button>

            <Menu
              id="Services"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Link to="/ADU" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>ADU</MenuItem>
              </Link>
              <Link to="/ASBUILT" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>AS BUILT</MenuItem>
              </Link>
              <Link to="/KITCHENREMODEL" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>KITCHEN REMODEL</MenuItem>
              </Link>
              <Link to="/MULTISTORYDUPLEX" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>MULTI STORY DUPLEX</MenuItem>
              </Link>
              <Link to="/NEWHOME" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>NEW HOME</MenuItem>
              </Link>
              <Link to="/PERMITSERVICES" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>PERMIT SERVICES</MenuItem>
              </Link>
              <Link to="/REPAIR" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>FIRE DAMAGE REPAIRS</MenuItem>
              </Link>
              <Link to="/ROOMADITION" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>ROOM ADDITION</MenuItem>
              </Link>
              <Link to="/TENANTIMPROVEMENT" style={{ textDecoration: 'none', display: 'block' }}>
              <MenuItem onClick={handleClose}>TENANT IMPROVEMENT</MenuItem> 
              </Link>
            </Menu>
            {/**END*/}
            <Link to="/Gallery" style={{ textDecoration: 'none', display: 'block', color: '#FFF'}}>
            <Button style={{  color: '#FFF'}} >Gallery</Button>
            </Link>
            <Link to="/About" style={{ textDecoration: 'none', display: 'block', color: '#FFF'}}>
            <Button style={{  color: '#FFF'}} >About UDS</Button>
            </Link>
            <Link to="/Contact" style={{ textDecoration: 'none', display: 'block',color: '#FFF' }}>
            <Button style={{  color: '#FFF'}} >Contact US</Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
}





function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
