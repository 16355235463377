export const CDescription = ' SCHEDULE A CONSULTATION'
export const Description = ' Check out our services and schedule your consultation today, and we can get started on your new project.'

export const PHONETITLE = 'TELEPHONE' 
export const EMAILTITLE = 'EMAIL' 
export const LOCATIONTITLE = 'LOCATION' 
export const PHONE = '(714) 853-3957' 
export const EMAIL = 'team@ultimatedraftingpro.com' 
export const ADDRESS = '12950 Paramount Blvd' 
export const SUITE = 'Suite 201' 
export const CITYSTATEZIP = 'Downey, CA 90242' 


//PHONETITLE, EMAILTITLE, LOCATIONTITLE, PHONE, EMAIL, ADDRESS, SUITE, CITYSTATEZIP 
