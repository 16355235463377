import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Values from './Values';
import Image from "../../Assets/IMAGES/About/Abo1.jpg"
import Image2 from "../../Assets/IMAGES/About/Abo6.jpg"
import Card from "@mui/material/Card";
import {OURSTORY, Integrity,IMG,MOURSTORY,MIMG,MIntegrity,MDIMG} from "./AboutInfo";
import {styled} from '@mui/material/styles';
import Form from '../Contact/Form'
import Footer from '../Footer/Footer'


const H1 = styled(Typography)(({ theme }) => ({
  color: 'white',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 60,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 100,
  },
}));

const Tittle  = (
  <React.Fragment>
    
        <Grid sx={{height: "100%"}} container alignItems="flex-end">
          <Grid item sm={12}>
            <H1>
            About UDS
            </H1>
          </Grid>
        </Grid>

  </React.Fragment>
);





export default function About() {
  return (
    <>
    <Box sx={{ flexGrow: 1, display: { xs: 'none',sm: 'none', md: 'none', lg: 'Block', xl: 'Block'} }}>
    <Paper sx={{height: "80vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "center"}}>
      {Tittle}
    </Paper>
    <Container maxWidth = "xl">
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Card elevation={0} >{Integrity}</Card>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end"  spacing={2}  alignItems="center">
        <Grid item xs={6}>
          <Card elevation={0} >
            {OURSTORY}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card elevation={6}> 
            {IMG} 
          </Card>
        </Grid>
      </Grid>
      <Grid>
        <Values/>
      </Grid>
    </Container>
      <Paper sx={{height: "40vh",backgroundImage: `url(${Image2})`,backgroundSize: "cover",backgroundPosition: "center"}}/>
      <Form/>
      <Grid>
      <Footer/>
      </Grid>
    </Box>

    <Box sx={{ flexGrow: 1, display: { xs: 'none',sm: 'none', md: 'Block', lg: 'none', xl: 'none'} }}>
    <Paper sx={{height: "40vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "center"}}>
      {Tittle}
    </Paper>
    <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Card elevation={0} sx={{ maxWidth: 1000 }}>{MIntegrity}</Card>
      </Grid>
    </Grid>
      <Grid container direction="row" justifyContent="flex-end"   alignItems="center">
        <Grid item xs={12}>
          <Card elevation={6}> 
            {MDIMG} 
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0}>
            {MOURSTORY}
          </Card>
        </Grid>
      </Grid>
      <Grid>
        <Values/>
      </Grid>
      <Paper sx={{height: "20vh",backgroundImage: `url(${Image2})`,backgroundSize: "cover",backgroundPosition: "center"}}/>
      <Grid>
        <Form/>
      </Grid>
      <Grid>
        <Footer/>
      </Grid>
    </Box>

    

    <Box sx={{ flexGrow: 1, display: { xs: 'Block',sm: 'Block', md: 'none', lg: 'none', xl: 'none'} }}>
      <Paper sx={{height: "40vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "center"}}>
        {Tittle}
      </Paper>
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Card elevation={0} sx={{ maxWidth: 1000 }}>{MIntegrity}</Card>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end"   alignItems="center">
        <Grid item xs={12}>
          <Card elevation={6}> 
            {MIMG} 
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0}>
            {MOURSTORY}
          </Card>
        </Grid>
      </Grid>
      <Grid>
        <Values/>
      </Grid>
      <Paper sx={{height: "20vh",backgroundImage: `url(${Image2})`,backgroundSize: "cover",backgroundPosition: "center"}}/>
      <Grid>
        <Form/>
      </Grid>
      <Grid>
        <Footer/>
      </Grid>
    </Box>

  </>
  );
}
