import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import logo from "../../Assets/IMAGES/Ser/sec1.jpg";
import { styled } from '@mui/material/styles';
import {KITCHENREMODEL, KITCHENREMODELInfo, CDescription, Description} from './ServicesContent'

const TitleStyyled = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 48,
  },
}));

const SubTitleStyyled = styled(Typography)(({ theme }) => ({
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 13,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 28,
  },
}));

export const SERVICES = (
  <React.Fragment>
    <CardContent>
      <TitleStyyled>
        {KITCHENREMODEL}
      </TitleStyyled>
      <SubTitleStyyled>
        {KITCHENREMODELInfo}
      </SubTitleStyyled>
    </CardContent>
  </React.Fragment>
);

export const CONSULTATION = (
  <React.Fragment>
    <CardContent>
      <Typography gutterBottom variant="h4" component="div" align="center" textTransform="uppercase" color="#003478">
        {CDescription}
      </Typography>
      <Typography variant="h6" >
      {Description}
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const MSERVICES = (
  <React.Fragment>
    <CardContent>
    <Typography gutterBottom variant="h4" component="div" align="center" textTransform="uppercase" color="#003478">
        {KITCHENREMODEL}
      </Typography>
      <Typography variant="body2" >
      {KITCHENREMODELInfo}      
        </Typography>
    </CardContent>
  </React.Fragment>
);

export const MCONSULTATION = (
  <React.Fragment>
    <CardContent>
      <Typography gutterBottom variant="h4" component="div" align="center" textTransform="uppercase" color="#003478">
      {CDescription}
      </Typography>
      <Typography variant="body2" >
      {Description}
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const IMG = (
  <React.Fragment>
    <CardMedia component="img" height="500" image={logo} alt="Media" />
  </React.Fragment>
);

export const XSIMG = (
  <React.Fragment>
    <CardMedia component="img" height="250" image={logo} alt="Media" />
  </React.Fragment>
);

export const SMIMG = (
  <React.Fragment>
    <CardMedia component="img" height="300" image={logo} alt="Media" />
  </React.Fragment>
);
