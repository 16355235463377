import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Image1 from "../../Assets/IMAGES/Index/P1.jpg";
import Image2 from "../../Assets/IMAGES/Index/P2.png";
import Image3 from "../../Assets/IMAGES/Index/P3.png";
import Image4 from "../../Assets/IMAGES/Index/P4.png";
import Description from "./ProcessInfo"




const mediacards = [
  {
      img: Image1,
      title: "CONSULTATION",
      description:"Take the time to schedule an appointment and meet with one of our project managers to consult about your project. This initial stage results in the creation of the first concept design, turning your vision into a plan."
  },
  {
      img: Image2,
      title: "PRELIMINARY DESIGN",
      description:"We analyze the project’s demands and requirements, thoroughly, and outline the possibilities that can be created. Along with following proper city regulations, we use our resources to create the initial blueprint."
  },
  {
      img: Image3,
      title: "PLAN DEVELOPMENT",
      description:"As we are closer to finalizing the final design, we develop our plan to creating and updating the initial blueprint as we see fit. We take into consideration the client’s needs, environmental conditions of the site, and address any other necessary factors."
  },
  {
    img: Image4,
    title: "SUBMITTAL",
    description:"The last stage comprises of the final approval by our client request and to be in ordinance with city guidelines and regulations.  As we finalize the ultimate design, we can complete the project and turning concept into reality."
  }
  ];



export default function ProcessCard( ) {
  return (
    < >
   
      

      <Box p={6} sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg: 'Block', xl: 'none' }}} >
      <Grid 
        container
        gap={3}
        justifyContent="center"
        alignItems="center">
        <Description/>
      {mediacards.map((item) =>(
        <Card sx={{maxWidth: 290, boxShadow: 0 }}>
          <CardMedia  style={{height: 290,width:290, }} image = {item.img}/>
          <CardContent style={{height: 250,}}>
          <Typography gutterBottom variant="h5" color="#003478" component="div">
            {item.title} 
          </Typography>
          <Typography variant="body1"   >
            {item.description}
          </Typography>
          </CardContent >  
        </Card>
        ))}
      </Grid>
      </Box>
      <Box p={6} sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg: 'none', xl: 'Block' }}} >
      <Grid 
        container
        gap={3}
        justifyContent="center"
        alignItems="center">
      <Description/>
      {mediacards.map((item) =>(
        <Card sx={{maxWidth: 300, boxShadow: 0 }}>
          <CardMedia  style={{height: 300,width:300, }} image = {item.img}/>
          <CardContent style={{height: 250,}}>
          <Typography gutterBottom variant="h5" color="#003478" component="div">
            {item.title} 
          </Typography>
          <Typography variant="body1"   >
            {item.description}
          </Typography>
          </CardContent >  
        </Card>
        ))}
      </Grid>
      </Box>
    <Box sx={{ display: { xs: 'none',sm: 'Block', md: 'Block', lg: 'none', xl: 'none'}}}>
    <Grid container justifyContent="space-evenly" alignItems="center">
    <Description/>
      {mediacards.map((item) =>(
        <Card sx={{display: 'flex' }}  elevation={0} >
          <CardMedia  style={{height: 300,width:500, }} image = {item.img}/>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' ,width:500}} >
              <Typography gutterBottom variant="h5"  component="div" color="#003478">
                {item.title} 
              </Typography>
              <Typography variant="body2" >
                {item.description}
              </Typography>
            </CardContent>  
          </Box>
        </Card>
        ))}
    </Grid>

    </Box>
    <Box sx={{ display: { xs: 'Block',sm: 'none', md: 'none', lg: 'none', xl: 'none'}}}>
    <Description/>
    <Grid container justifyContent="space-evenly" alignItems="center">
      {mediacards.map((item) =>(
        <Card sx={{display: 'flex' }}  elevation={0} >
          <CardMedia  style={{height: 200,width:200, }} image = {item.img}/>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' ,width:200}} >
              <Typography gutterBottom variant="body1"  component="div" color="#003478">
                {item.title} 
              </Typography>
              <Typography variant="caption" >
                {item.description}
              </Typography>
            </CardContent>  
          </Box>
        </Card>
        ))}
    </Grid>

    </Box>
  
      
    </>
);
}