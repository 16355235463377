import * as React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Social from "./Social";
import Logo from "../../Assets/IMAGES/Logo/LogoWhite.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Button } from "@mui/material";
import Link from '@mui/material/Link';
import {useNavigate } from "react-router-dom";




const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper
}));

const Header = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper
}));

const Body = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper
}));

const CellHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper
}));

const CellBody = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper
}));

const LinkBody = styled(Link)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.background.paper,
  variant:"body2"
}));
export default function BasicStack() {
  let navigate = useNavigate();
  return (
    
    <div >
    <Container >
      <>
      <Box sx={{ flexGrow: 1,backgroundColor: '#003478',p: 1, display: { xs: 'none',sm: 'none', md: 'none', lg: 'Block', xl: 'Block'}}}>
      <Grid 
          container 
          gap={1}
          justifyContent="space-between"
          alignItems="flex-start">
            <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
              <Stack spacing={0}>
                <Title>Ultimate Drafting Services</Title>
                <Header>Location</Header>
                <Body>12950 Paramount Blvd </Body>
                <Body>Suite 201 </Body>
                <Body>Downey, CA 90242 </Body>
                <Header>Telephone</Header>
                <Body>(714) 853-3957</Body>
                <Header>E-mail</Header>
                <Body>team@ultimatedraftingpro.com</Body>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={2} lg={2} xl={3}>
              <Stack spacing={0}>
                <Button color="inherit" onClick={() => {navigate("/")} }>
                <Title>HOME</Title>
                </Button>
                <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
                <Title>GALLERY</Title>
                </Button>
                <Button color="inherit" onClick={() => {navigate("/About")} }>
                <Title>ABOUT</Title>
                </Button>
                <Button color="inherit" onClick={() => {navigate("/Contact")} }>
                <Title>CONTACT US</Title>
                </Button>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                <Title>SERVICES</Title>
                  <LinkBody underline="hover" color="inherit"  onClick={() => {navigate("/ADU")} }>
                    ADU
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/ASBUILT")} }>
                    As Built
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/KITCHENREMODEL")} }>
                    Kitchen Remodel
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/MULTISTORYDUPLEX")} }>
                    Multistory Duplex
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/NEWHOME")} }>
                    New Home
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/PERMITSERVICES")} }>
                    Permit Services
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/REPAIR")} }>
                    Fire Damage Repair
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/ROOMADITION")} }>
                    Room Additions
                  </LinkBody>
                  <LinkBody underline="hover" color="inherit" onClick={() => {navigate("/TENANTIMPROVEMENT")} }>
                    Tenant Improvement
                  </LinkBody>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={2} lg={3} xl={2}>
              <Box>
                <img src={Logo} alt="logo" width= "350" />
              </Box>
            </Grid>
          </Grid>
          <br/>
        </Box>
      <Box sx={{ flexGrow: 1,backgroundColor: '#003478',p: 1, display: { xs: 'none',sm: 'none', md: 'Block', lg: 'none', xl: 'none'}}}>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Grid item > 
                <Header>Location</Header>
                <Body>12950 Paramount Blvd</Body>
                <Body >Suite 201</Body>
                <Body>Downey, CA 90242</Body>
              </Grid>
              <Grid item> 
                <Title >Telephone</Title>
                <Body >(714) 853-3957</Body>
              </Grid>
              <Grid item> 
                <Title >E-mail</Title>
                <Body>team@ultimatedraftingpro.com</Body>
              </Grid>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Button color="inherit" onClick={() => {navigate("/")} }>
              <Title>HOME</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <Title>SERVICES</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <Title>GALLERY</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/About")} }>
              <Title>ABOUT</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Contact")} }>
              <Title>CONTACT US</Title>
              </Button>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Stack spacing={0}>
                {/**<Title>Need fuel to kick start your creativity?</Title>*/}
              </Stack>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Box>
                <img src={Logo} alt="logo" width= "350" />
              </Box>
            </Grid>
          <br/>
        </Box>
        <Box sx={{ flexGrow: 1,backgroundColor: '#003478',p: 1, display: { xs: 'none',sm: 'Block', md: 'none', lg: 'none', xl: 'none'}}}>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
            <Grid item > 
                <CellHeader>Location</CellHeader>
                <CellBody>12950 Paramount Blvd</CellBody>
                <CellBody >Suite 201</CellBody>
                <CellBody>Downey, CA 90242</CellBody>
              </Grid>
              <Grid item> 
                <CellHeader >Telephone</CellHeader>
                <CellBody >(714) 853-3957</CellBody>
              </Grid>
              <Grid item> 
                <CellHeader >E-mail</CellHeader>
                <CellBody >team@ultimatedraftingpro.com</CellBody>
              </Grid>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
            <Button color="inherit" onClick={() => {navigate("/")} }>
              <Title>HOME</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <Title>SERVICES</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <Title>GALLERY</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/About")} }>
              <Title>ABOUT</Title>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Contact")} }>
              <Title>CONTACT US</Title>
              </Button>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Stack spacing={0}>
                {/**<Title>Need fuel to kick start your creativity?</Title>*/}
              </Stack>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Box>
                <img src={Logo} alt="logo" width= "350" />
              </Box>
            </Grid>
          <br/>
        </Box>
        <Box sx={{ flexGrow: 1,backgroundColor: '#003478',p: 1, display: { xs: 'Block',sm: 'none', md: 'none', lg: 'none', xl: 'none'}}}>
            <Grid container >
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#003478' }}>
              <ListItem alignItems="flex-start">
                
                <ListItemText
                  primary={
                    <React.Fragment>
                      <CellHeader >Location</CellHeader>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <CellBody>12950 Paramount Blvd</CellBody>
                      <CellBody >Suite 201</CellBody>
                      <CellBody >Downey, CA 90242</CellBody>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <React.Fragment>
                      <CellHeader >Telephone</CellHeader>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <CellBody >(714) 853-3957</CellBody>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem alignItems="flex-start">

                <ListItemText

                  primary={
                    <React.Fragment>
                      <CellHeader >E-Mail</CellHeader>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <CellBody >team@ultimatedraftingpro.com</CellBody>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
            <Button color="inherit" onClick={() => {navigate("/")} }>
              <CellBody>HOME</CellBody>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <CellBody>SERVICES</CellBody>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Gallery")} }>
              <CellBody>GALLERY</CellBody>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/About")} }>
              <CellBody>ABOUT</CellBody>
              </Button>
              <Button color="inherit" onClick={() => {navigate("/Contact")} }>
              <CellBody>CONTACT US</CellBody>
              </Button>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Stack spacing={0}>
                {/**<Title>Need fuel to kick start your creativity?</Title>*/}
              </Stack>
            </Grid>
            <br/>
            <Grid container direction="row" spacing={2} justifyContent="space-evenly">
              <Box>
                <img src={Logo} alt="logo" width= "350" />
              </Box>
            </Grid>
          <br/>
        </Box>
      </>
        </Container >
    </div>
  );
}
