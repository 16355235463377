import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Footer from '../../Pages/Footer/Footer'

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import gal1 from "../../Assets/IMAGES/Gallery/ProjectB/PrB1.jpg";
import gal2 from "../../Assets/IMAGES/Gallery/ProjectB/PrB2.jpg";
import gal3 from "../../Assets/IMAGES/Gallery/ProjectB/PrB3.jpg";
import gal4 from "../../Assets/IMAGES/Gallery/ProjectB/PrB4.jpg";
import gal5 from "../../Assets/IMAGES/Gallery/ProjectB/PrB5.jpg";
import gal6 from "../../Assets/IMAGES/Gallery/ProjectB/PrB6.jpg";



const Title = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'left',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 34,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'left' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
}));



export default function BasicGrid() {
  
  return (
    <Paper>
    <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Container maxWidth ="xl">
        <Box m="auto" >
        <Stack p={5} spacing={3}>
          <Title>
          BELHAVEN ST
          </Title>
          <Description>
          Convert existing detached garage to new ADU 332 sq. ft.+ 2-story ADU addition 827 sq. ft.
          </Description>
        </Stack>
        </Box>
      </Container>
      
      <Container maxWidth="xl">
        <Grid container direction="column"  alignItems="center" gap={6} justifyContent="center">
          <Grid item xs={4}>
            <Card elevation={0}>{ONE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{TWO}</Card>
          </Grid>
          <Grid item  xs={4} >
            <Card elevation={0}>{THREE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FOUR}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FIVE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{SIX}</Card>
          </Grid>
      
        </Grid>
      </Container>
      <Footer/>
    </Paper>
    
  );
}


// Need to remove this to a different Typescript file 

export const ONE = (
  <React.Fragment>
    
    <CardContent>
    <CardMedia component="img"  image={gal1} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const TWO = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal2} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const THREE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal3} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FOUR = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal4} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FIVE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"image={gal5} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const SIX = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal6} alt="Media" />
    </CardContent>
  </React.Fragment>
);

