import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Image from "../../Assets/IMAGES/About/Abo3.jpg";
import Image1 from "../../Assets/IMAGES/About/Abo4.jpg";
import Image2 from "../../Assets/IMAGES/About/Abo5.jpg";
import {Values, MValues} from "./AboutInfo";

const mediacards = [
  {
    img: Image,
    title: "INTEGRITY",
    description:
    "We treat every project with a high level of significance and focus on maximum accuracy and precision. We understand the importance of every project, so it’s crucial that we don’t cut corners and produce plans that surpass expectations."
  },
  {
    img: Image1,
    title: "PROFESSIONALISM",
    description:
    "Our team is comprised of expertise and experience to collaborate and communicate with you effectively. Your needs as our client is always a priority, and we are set on excellent customer service and exceptional services that fit your design."
  },
  {
    img: Image2,
    title: "DETERMINATION",
    description:
    "Whether you are interested in a small or large project, our team and network of experts are driven towards completing every project. Your investment is well taken care of as we outline new projects, builds, and models that will elevate your home."
  }
  ];



export default function MediaCard( ) {
  return (
  <>
    <Box p={6} sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg: 'Block', xl: 'Block' }}} >
      {Values}
      <Grid 
      sx={{height: "100%",
      zIndex: 100,
      position: "relative"}}
        container
        justifyContent="space-around"
        alignItems="center">
      {mediacards.map((item) =>(
        <Card sx={{maxWidth: 396 }}  elevation={0} >
          <CardMedia  style={{height: 396,}} image = {item.img}/>
          <CardContent  >
          <Typography gutterBottom variant="h5"  component="div" color="#003478">
            {item.title} 
          </Typography>
          <Typography variant="body2" >
            {item.description}
          </Typography>
          </CardContent >  
        </Card>
        ))}
      </Grid>
    </Box>

    <Box sx={{ display: { xs: 'none',sm: 'none', md: 'Block', lg: 'none', xl: 'none'}}}>
    {MValues}
    <Grid container justifyContent="space-evenly" alignItems="center">
      {mediacards.map((item) =>(
        <Card sx={{display: 'flex' }}   elevation={0} >
          <CardMedia  style={{ height: 170,width:500,}} image = {item.img}/>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent  >
          <Typography gutterBottom variant="body1"  component="div" color="#003478">
            {item.title} 
          </Typography>
          <Typography variant="caption" >
            {item.description}
          </Typography>
          </CardContent>  
          </Box>
        </Card>
        ))}
      </Grid>
    </Box>

    <Box sx={{ display: { xs: 'Block',sm: 'Block', md: 'none', lg: 'none', xl: 'none'}}}>
    {MValues}
    <Grid container justifyContent="space-evenly" alignItems="center">
      {mediacards.map((item) =>(
        <Card sx={{display: 'flex' }}   elevation={0} >
          <CardMedia  style={{ height: 170,width:1000,}} image = {item.img}/>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent  >
          <Typography gutterBottom variant="body1"  component="div" color="#003478">
            {item.title} 
          </Typography>
          <Typography variant="caption" >
            {item.description}
          </Typography>
          </CardContent>  
          </Box>
        </Card>
        ))}
      </Grid>
    </Box>

    </>
  );
}