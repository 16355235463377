import * as React from 'react';
import Hero from "./Hero"
import MediaCard from "./MediaCard"
import OurProcess from "./ProcessCards"
import Services from "./Services"
import Form from "../Contact/Form"
import Footer from "../Footer/Footer"





export default function Main() {
  return (
<div>
  <Hero/>
  <MediaCard/>
  <Services/>
  <OurProcess/>
  <Form/>
  <Footer/>
</div>
);
}

