import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Image from "../../Assets/IMAGES/Index/Std1.jpg";
import Image1 from "../../Assets/IMAGES/Index/Std2.jpg";
import Image2 from "../../Assets/IMAGES/Index/Std3.jpg";

import Description from "./description"
import { Container } from '@mui/material';


const mediacards = [
  {
      img: Image,
      title: "ULTIMATE DESIGN",
      description:
      "Ultimate Drafting Services firmly believes in creating designs that surpass expectations while maintaining a solid value of integrity behind every project."
  },
  {
      img: Image1,
      title: "PRECISION  DRAFTING",
      description:
      "Our experienced team and network of drafters, designers, and specialists, collaborate to outline new projects, builds, and models that elevate your home."
  },
  {
      img: Image2,
      title: "EXCELLENT SERVICES",
      description:
      "To the best of our ability, we aim to offer our best services with consultations and drafts that fit our client’s wants and needs to the highest degree."
  }
  ];




export default function MediaCard( ) {
  
  return (
  // this is for green and red
 <>
 
    <Box p={6} sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg: 'Block', xl: 'Block' }}} >
      <Description/>
      <Container maxWidth="xl">
        <Grid sx={{height: "100%",zIndex: 100,position: "relative"}} container justifyContent="space-around" alignItems="center">
          {mediacards.map((item) =>(
            <Card sx={{maxWidth: 396 }}  elevation={0} >
              <CardMedia  style={{height: 396,}} image = {item.img}/>
              <CardContent  >
              <Typography gutterBottom variant="h5"  component="div" color="#003478">
                {item.title} 
              </Typography>
              <Typography variant="body1" >
                {item.description}
              </Typography>
              </CardContent >  
            </Card>
            ))}
        </Grid>
      </Container>
    </Box>

    <Box sx={{ display: { xs: 'none',sm: 'Block', md: 'Block', lg: 'none', xl: 'none'}}}>
      <Description/>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-evenly" alignItems="center">
          {mediacards.map((item) =>(
            <Card sx={{maxWidth: 250 }}  elevation={0} >
              <CardMedia  style={{height: 250,}} image = {item.img}/>
              <CardContent  >
              <Typography gutterBottom variant="h6"  component="div" color="#003478">
                {item.title} 
              </Typography>
              <Typography variant="body2" >
                {item.description}
              </Typography>
              </CardContent>  
            </Card>
            ))}
          </Grid>
      </Container>
    </Box>

    <Box sx={{ display: { xs: 'Block',sm: 'none', md: 'none', lg: 'none', xl: 'none'}}}>
    <Description/>
    <Grid container justifyContent="space-evenly" alignItems="center">
      {mediacards.map((item) =>(
        <Card sx={{display: 'flex' }}    elevation={0} >
          <CardMedia  style={{height: 250 }} image = {item.img}/>
          <CardContent sx={{ flex: '1 0 auto' ,width:200}} >
          <Typography gutterBottom variant="body1"  component="div" color="#003478">
            {item.title} 
          </Typography>
          <Typography variant="caption" >
            {item.description}
          </Typography>
          </CardContent>  
        </Card>
        ))}
      </Grid>
    </Box>

    </>
  );
}