import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import {styled} from '@mui/material/styles';
import Image from "../../Assets/IMAGES/Ser/Ser4.jpg";
//
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {SERVICES, MSERVICES, MCONSULTATION, IMG, XSIMG,SMIMG } from "./ROOMADITIONINFO";
import Footer from "../../Pages/Footer/Footer";
import AditinalServices from './OS'
import Form from '../Contact/ServiceForm'

const H1 = styled(Typography)(({ theme }) => ({
  color: 'white',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 60,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 80,
  },
}));

const Tittle  = (
  <React.Fragment>
  
        <Grid sx={{height: "100%"}} container alignItems="flex-end">
          <Grid item sm={12}>
            <H1>
            Room Addition
            </H1>
          </Grid>
        </Grid>
  
  </React.Fragment>
);

export default function BoxSx() {

  return (
    <>
    
  <Box sx={{ flexGrow: 1, display: { xs: 'none',sm: 'none', md: 'Block', lg: 'Block', xl: 'Block'} }}>
    <Paper sx={{height: "80vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "bottom"}}>
      {Tittle}
      </Paper>  
    <Container maxWidth="xl">
      <Grid container  direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Card elevation={0}>{SERVICES}</Card>
        </Grid>
      </Grid>
      <Grid container  alignItems="center">
        <Grid item xs={6}>
          <Card elevation={6}>{IMG}</Card>
        </Grid>
        <Grid item xs={6}>
          <Form/>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <AditinalServices/>
      </Grid>
    </Container>     

    <Footer/>
  </Box>


  <Box sx={{ flexGrow: 1, display: { xs: 'Block',sm: 'none', md: 'none', lg: 'none', xl: 'none'} }}>
    <Paper sx={{ height: "30vh", backgroundImage: `url(${Image})`, backgroundSize: "cover", backgroundPosition: "center"}}>
      {Tittle}
      </Paper>
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card elevation={0}>{MSERVICES}</Card>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12}>
            <Card elevation={6}>{XSIMG}</Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} sx={{ maxWidth: 700 }}>
            {MCONSULTATION}
            </Card>
          </Grid>
        </Grid>
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
        <AditinalServices/>
        </Grid>
        <Grid>
        <Form/>
        </Grid>
      </Grid>
      <Footer/>
  </Box>

  <Box sx={{ flexGrow: 1, display: { xs: 'none',sm: 'Block', md: 'none', lg: 'none', xl: 'none'} }}>
    <Paper sx={{ height: "90vh", backgroundImage: `url(${Image})`, backgroundSize: "cover", backgroundPosition: "center"}}>
    {Tittle}
    </Paper>
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card elevation={0}>{MSERVICES}</Card>
          </Grid>
        </Grid>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12}>
            <Card elevation={6}>{SMIMG}</Card>
          </Grid>
          <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card elevation={0} >
            {MCONSULTATION}
            </Card>
          </Grid>
        </Grid>
        </Grid>
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
          <AditinalServices/>
          </Grid>
        </Grid>
        <Grid>
        <Form/>
        </Grid>
        <Footer/>
    </Box>
    </>
  );
}
