import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Footer from '../../Pages/Footer/Footer'

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import gal1 from "../../Assets/IMAGES/Gallery/ProjectC/PrC1.jpg";
import gal2 from "../../Assets/IMAGES/Gallery/ProjectC/PrC2.jpg";
import gal3 from "../../Assets/IMAGES/Gallery/ProjectC/PrC3.jpg";
import gal4 from "../../Assets/IMAGES/Gallery/ProjectC/PrC4.jpg";
import gal5 from "../../Assets/IMAGES/Gallery/ProjectC/PrC5.jpg";
import gal6 from "../../Assets/IMAGES/Gallery/ProjectC/PrC6.jpg";
import gal7 from "../../Assets/IMAGES/Gallery/ProjectC/PrC7.jpg";
import gal8 from "../../Assets/IMAGES/Gallery/ProjectC/PrC8.jpg";




const Title = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'left',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 34,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'left' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
}));



export default function BasicGrid() {
  
  return (
    <Paper>
      
    <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Container maxWidth ="xl">
      <Box m="auto" >
      <Stack p={5} spacing={3}>
        <Title>
        CRANER AVE
        </Title>
        <Description>
        Remodel kitchen and remove wall in order to
        create open concept. Modify existing bedrooms on the right
        to accommodate master bath, hall bath, and closets.
        Relocate windows and add French doors and all details as
        per provided hand sketch. Redesign ADU and make it a one
        bedroom ADU. Add new covered patio to rear of residence
        including new concrete landing. For the main house relocate
        all bedroom and bath walls in order to create adequate space
        for each room. Relocate windows to accommodate the
        redesigned rooms.
        </Description>
      </Stack>
      </Box>
      </Container>
      <Container maxWidth="xl">
      <Box>
        <Grid container direction="column"  alignItems="center" gap={6} justifyContent="center">
          <Grid item xs={4}>
            <Card elevation={0}>{ONE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{TWO}</Card>
          </Grid>
          <Grid item  xs={4} >
            <Card elevation={0}>{THREE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FOUR}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FIVE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{SIX}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{SEVEN}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{EIGHT}</Card>
          </Grid>
        </Grid>
      </Box>
      </Container>
      <Footer/>
    </Paper>
    
  );
}


// Need to remove this to a different Typescript file 

export const ONE = (
  <React.Fragment>
    
    <CardContent>
    <CardMedia component="img" image={gal1} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const TWO = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal2} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const THREE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal3} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FOUR = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal4} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FIVE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal5} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const SIX = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"image={gal6} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const SEVEN = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal7} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const EIGHT = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal8} alt="Media" />
    </CardContent>
  </React.Fragment>
);
