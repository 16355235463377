import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Footer from '../../Pages/Footer/Footer'
import {useNavigate } from "react-router-dom";
import Image from "../../Assets/IMAGES/Gallery/main.jpg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {styled} from '@mui/material/styles';
import Form from '../Contact/Form'
import { Button} from '@mui/material';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from '@mui/material/CardActions';
import gal1 from "../../Assets/IMAGES/Gallery/gal2.jpg";
import gal2 from "../../Assets/IMAGES/Gallery/gal3.jpg"
import gal3 from "../../Assets/IMAGES/Gallery/gal2.jpg";
import gal4 from "../../Assets/IMAGES/Gallery/Gal5.jpg";




const H1 = styled(Typography)(({ theme }) => ({
  color: 'white',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 60,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 100,
  },
}));

const Tittle  = (
  <React.Fragment>
    
        <Grid sx={{height: "100%"}} container alignItems="flex-end">
          <Grid item sm={12}>
            <H1>
            Gallery
            </H1>
          </Grid>
        </Grid>
    
  </React.Fragment>
);


export default  function Gallery() {
  let navigate = useNavigate();

  return (
    <>
    
    
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg: 'Block', xl: 'Block' }}}>
      <Paper sx={{height: "80vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "center"}}>
        {Tittle}
        <Box component="span" sx={{ display: "block", p: 4}}/>
      </Paper>
        <Grid container gap={6} justifyContent="center">
          <Grid item  lg={4} xl={4}>
            <Card elevation={0}>
              {ONE}
              <Button color="inherit" onClick={() => {navigate("/ProjectA")} }>
              VIEW Gallery
              </Button>
            </Card>
          </Grid>
          <Grid item lg={4} xl={4}>
            <Card elevation={0}>
              {TWO}
              <Button color="inherit" onClick={() => {navigate("/ProjectB")} }>
              VIEW Gallery
              </Button>
            </Card>
          </Grid>
          <Grid item  lg={4} xl={4}>
            <Card elevation={0}>
              {THREE}
              <Button color="inherit" onClick={() => {navigate("/ProjectC")} }>
              VIEW Gallery
              </Button>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Form/>
        </Grid>
      </Box>
 
    <Box sx={{ display: { xs: 'Block',sm: 'Block', md: 'Block', lg: 'none', xl: 'none'}}}>
    <Paper sx={{height: "40vh",backgroundImage: `url(${Image})`,backgroundSize: "cover",backgroundPosition: "center"}}>
      {Tittle}
    </Paper>
    <Box component="span" sx={{ display: "block", p: 4}}/>
    <Container maxWidth="md">
        <Grid container gap={3} justifyContent="center">
          <Grid item >
          <Card >
              {ONEm}
              <CardActions>
                <Button color="inherit" onClick={() => {navigate("/ProjectA")} }>
                Project Gallery
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item >
            <Card>
            {TWOm}
              <CardActions>
                <Button color="inherit" onClick={() => {navigate("/ProjectB")} }>
                Project Gallery
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item >
            <Card >
            {THREEm}
            <CardActions>
            <Button color="inherit" onClick={() => {navigate("/ProjectC")} }>
              Project Gallery
            </Button>
            </CardActions>
            </Card>
          </Grid>
        </Grid>
        </Container>
        <Grid container>
          <Form/>
        </Grid>
      </Box>
      <Footer/>
      </>
  );
}


// Need to remove this to a different Typescript file 

export const ONE = (
  <React.Fragment>
    
    <CardContent>
    <CardMedia component="img" height="400" image={gal1} alt="Media" />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        LIMERICK AVE
      </Typography>
      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
        WINNETKA, CA
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const TWO = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" height="400" image={gal2} alt="Media" />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      BELHAVEN ST 
      </Typography>
      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
      LOS ANGELES, CA 
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const THREE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" height="400" image={gal4} alt="Media" />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       CRANER AVE
      </Typography>
      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
        WINNETKA, CA
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const FOUR = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" height="400" image={gal4} alt="Media" />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        LIMERICK AVE
      </Typography>
      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
        LOS ANGELES, CA
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const ONEm = (
  <React.Fragment>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <CardMedia component="img"  image={gal2} alt="Media" />
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" >
      LIMERICK AVE
      </Typography>
      <Typography sx={{ fontSize: 12 }} color="text.secondary" >
      WINNETKA, CA
      </Typography>
    </CardContent>
    </Box>
  </React.Fragment>
);

export const TWOm = (
  <React.Fragment>
    
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <CardMedia component="img" image={gal3} alt="Media" />
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" >
        BELHAVEN ST
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" >
        WINNETKA, CA
        </Typography>
      </CardContent>
      </Box>
  </React.Fragment>
);



export const THREEm = (
  <React.Fragment>
    
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardMedia component="img" image={gal4} alt="Media" />
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        BELHAVEN ST
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
        LOS ANGELES, CA
        </Typography>
      </CardContent>
      </Box>
  </React.Fragment>
);
