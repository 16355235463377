import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import IMGCON from "../../Assets/IMAGES/Contact/Con2.jpg"
import { styled } from '@mui/material/styles';
import {CDescription, Description, PHONETITLE, EMAILTITLE, LOCATIONTITLE, PHONE, EMAIL, ADDRESS, SUITE, CITYSTATEZIP } from './Content'

const TitleStyyled = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  textAlign: 'left',
  color:"#003478"
}));
const SubTitleStyyled = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textAlign: 'left',

}));
const INFOTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textAlign: 'left',
}));
const INFOSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
}));


export const SERVICES = (
  <React.Fragment>
    <CardContent>
      <TitleStyyled>
        {CDescription}
      </TitleStyyled>
      <SubTitleStyyled>
        {Description}
      </SubTitleStyyled>
    </CardContent>
  </React.Fragment>
);

export const INFO = (
  <React.Fragment>
    <CardContent>
      <INFOTitle>
        {LOCATIONTITLE}
      </INFOTitle>
      <INFOSubTitle>
        {ADDRESS}
      </INFOSubTitle>
      <INFOSubTitle>
        {SUITE}
      </INFOSubTitle>
      <INFOSubTitle>
        {CITYSTATEZIP}
      </INFOSubTitle>
    </CardContent>
    <CardContent>
      <INFOTitle>
        {PHONETITLE}
      </INFOTitle>
      <INFOSubTitle>
        {PHONE}
      </INFOSubTitle>
    </CardContent>
    <CardContent>
      <INFOTitle>
        {EMAILTITLE}
      </INFOTitle>
      <INFOSubTitle>
        {EMAIL}
      </INFOSubTitle>
    </CardContent>
  </React.Fragment>
);

export const MSERVICES = (
  <React.Fragment>
    <CardContent>
    <Typography gutterBottom variant="h6" component="div" align="center" textTransform="uppercase" color="#003478">
        {CDescription}
      </Typography>
      <Typography variant="body2" color="text.secondary">
      {Description}      
        </Typography>
    </CardContent>
  </React.Fragment>
);

export const MCONSULTATION = (
  <React.Fragment>
    <CardContent>
      <Typography gutterBottom variant="h4" component="div" align="center" textTransform="uppercase" color="#003478">
      {CDescription}
      </Typography>
      <Typography variant="body2" color="text.secondary">
      {Description}
      </Typography>
    </CardContent>
  </React.Fragment>
);

export const IMG = (
  <React.Fragment>
    <CardMedia component="img"  image={IMGCON} alt="Media" />
  </React.Fragment>
);

export const XSIMG = (
  <React.Fragment>
    <CardMedia component="img" height="250" image={IMGCON} alt="Media" />
  </React.Fragment>
);

export const SMIMG = (
  <React.Fragment>
    <CardMedia component="img" height="300" image={IMGCON} alt="Media" />
  </React.Fragment>
);