import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Image5 from "../../Assets/IMAGES/Index/as1.jpg";
import Image6 from "../../Assets/IMAGES/Index/as2.jpg";
import Image7 from "../../Assets/IMAGES/Index/as3.jpg";
import Image8 from "../../Assets/IMAGES/Index/as4.jpg";
import Image9 from "../../Assets/IMAGES/Index/as5.jpg";
import Stack from '@mui/material/Stack';
import {useNavigate } from "react-router-dom";


const images3 = [
  {
    url: Image5,
    title: 'FIRE DAMAGE',
    width: '20%',
    path: '/REPAIR',
  },
  {
    url: Image6,
    title: 'TENANT IMPROVEMENT',
    width: '20%',
    path: '/TENANTIMPROVEMENT',
  },
  {
    url: Image7,
    title: 'MULTISTORY DUPLEX',
    width: '20%',
    path: '/MULTISTORYDUPLEX',
  },
  {
    url: Image8,
    title: 'PERMIT SERVICES',
    width: '20%',
    path: '/PERMITSERVICES',
  },
  {
    url: Image9,
    title: 'AS-BUILT',
    width: '20%',
    path: '/ASBUILT',
  },
  
];


const ImageButtonSmall = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 60%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));
const Title = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 48,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 20,
    
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 28,
  },
}));


export default function OS() {
  let navigate = useNavigate();
  return (
    <>
      <Box m="auto"  >
        <Stack spacing={3}>
          <Title>
          ADDITIONAL SERVICES
          </Title>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', }}>
        {images3.map((image) => (
          <ImageButtonSmall
            focusRipple
            onClick={() => {navigate(`${image.path}`)} }
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="h6"
                color="inherit"
              >
                {image.title}
              </Typography>
            </Image>
          </ImageButtonSmall>
        ))}
      </Box>
      <Box m="auto" >
          <SubTitle>
          If you are looking for any additional services not listed. 
          <br/>
          Please feel free to contact us, and we will make sure to accommodate your project.
          </SubTitle>
      </Box>
      </>
  );
}


