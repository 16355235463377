import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Footer from '../../Pages/Footer/Footer'

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import gal1 from "../../Assets/IMAGES/Gallery/ProjectA/PrA1.jpg";
import gal2 from "../../Assets/IMAGES/Gallery/ProjectA/PrA2.jpg";
import gal3 from "../../Assets/IMAGES/Gallery/ProjectA/PrA3.jpg";
import gal4 from "../../Assets/IMAGES/Gallery/ProjectA/PrA4.jpg";
import gal5 from "../../Assets/IMAGES/Gallery/ProjectA/PrA5.jpg";
import gal6 from "../../Assets/IMAGES/Gallery/ProjectA/PrA6.jpg";
import gal7 from "../../Assets/IMAGES/Gallery/ProjectA/PrA7.jpg";
import gal8 from "../../Assets/IMAGES/Gallery/ProjectA/PrA8.jpg";
import gal9 from "../../Assets/IMAGES/Gallery/ProjectA/PrA9.jpg";
import gal10 from "../../Assets/IMAGES/Gallery/ProjectA/PrA10.jpg";

const Title = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'left',
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 34,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'left' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
}));



export default function BasicGrid() {
  
  return (
    <Paper >
    <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Container maxWidth ="xl">
        <Box m="auto" >
        <Stack p={5} spacing={3}>
          <Title>
          LIMERICK AVE
          </Title>
          <Description>
          convert existing garage to a 1 bedroom ADU. Remodel existing 1,732 sf 3 bedroom residence and incorporate new 4th bedroom with bath within existing footprint. 
          Fire damage repair of damaged rafters, ceiling joists, drywall, ducts, insulation, windows, and roofing per fire report. 
          Create open concept by removing chimney and wall that separates kitchen and living room and place a beam to support the roof and ceiling. 
          Legalize existing enclosed patio with new walls, footings, and sliding glass door. Demo interior wall and replace it with new beam so the living room can be part of the new addition.  
          Existing roof and slab to remain and place new treated floor joist on top of existing slab to meet existing floor height. 
          New patio cover across rear residence. New patio cover to be within required setbacks.
          </Description>
        </Stack>
        </Box>
      </Container>
      <Container maxWidth="xl">
        
        <Grid container direction="column"  alignItems="center" gap={6} justifyContent="center">
          <Grid item xs={4}>
            <Card elevation={0}>{ONE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{TWO}</Card>
          </Grid>
          <Grid item  xs={4} >
            <Card elevation={0}>{THREE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FOUR}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{FIVE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{SIX}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{SEVEN}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{EIGHT}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{NINE}</Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0}>{TEN}</Card>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </Paper>
    
  );
}


// Need to remove this to a different Typescript file 

export const ONE = (
  <React.Fragment>
    
    <CardContent>
    <CardMedia component="img"image={gal1} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const TWO = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal2} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const THREE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"image={gal3} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FOUR = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal4} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const FIVE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal5} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const SIX = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal6} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const SEVEN = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal7} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const EIGHT = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img" image={gal8} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const NINE = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal9} alt="Media" />
    </CardContent>
  </React.Fragment>
);

export const TEN = (
  <React.Fragment>
    <CardContent>
    <CardMedia component="img"  image={gal10} alt="Media" />
    </CardContent>
  </React.Fragment>
);



