

export const CDescription = ' Schedule A Consultation'
export const Description = ' Check out our services and schedule your consultation today, and we can get started on your new project.'

export const ADU = <br/>
export const ADUInfo = 'Are you looking to make an additional rental income or expand your home? Our team has you covered. We abide by all city limits and regulations to accurately create an accessory dwelling unit that perfectly fits your home.'

export const ASBUILD = <br/>
export const ASBUILDInfo = ' Using our high-tech laser measurement systems and drone video recordings,  we can duplicate your house with remarkable accuracy. This service is applicable towards your home, business, or any other structure that you are looking to develop, renovate, or maintain during the construction process.'

export const KITCHENREMODEL = <br/>
export const KITCHENREMODELInfo = 'We understand that the kitchen is one of the most crucial parts of your home.  Our team ensures that we take care of your project while maintaining the integrity of your home. We specialize in open concept, cabinet and floor layouts, and creating space for your kitchen.'

export const MULTISTORYDUPLEX = <br/>
export const MULTISTORYDUPLEXInfo = 'Depending on the zone and space provided, we can provide multistory duplexes that function best with the layout while maintaining the project economically friendly. Our team ensures to provide the most effective blueprints that are suitable for any budget.'

export const NEWHOME = <br/>
export const NEWHOMEInfo = ' We understand that planning and building a new home can be a tedious project, but the investment will pay off. That’s why our team of professionals ensures that your investment is in great hands, and our communication and commitment are to make your vision come true.'

export const PERMITSERVICES = <br/>
export const PERMITSERVICESInfo = ' We offer permit services when working on your project, saving you time from dealing with city rules and regulations. Given our history, we have the experience to expedite the process and effectively communicate with the city to meet both your needs and that of the city.'

export const REPAIR = <br/>
export const REPAIRInfo = 'With several successful projects and experiences that we’ve done in the past,  we can confidently help your need with repairs.  In addition, we offer construction documentation and a professional network, having worked with contractors,  insurance companies, and engineers, ensuring all repair plans abide by city guidelines.'

export const ROOMADITION = <br/>
export const ROOMADITIONInfo = ' Additions and extensions are necessary when modifying your home and creating more space. Whether creating a master bedroom or looking to extend your home for your growing family, we will handle this project accordingly and professionally.'

export const TENANTIMPROVEMENT = <br/>
export const TENANTIMPROVEMENTInfo = ' For those who want to open a new business on an existing commercial space or providing updates to your current property, we have your covered. These projects can include the changes in lights, ceilings, walls, and floors,  among other services, that abide with your specifications.'
