import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import IMGCON from "../../Assets/IMAGES/About/abo2.jpg"
import Box from "@mui/material/Box";

export const Integrity = (
  <React.Fragment>
    <CardContent>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Typography
        gutterBottom
        variant="h3"
        component="div"
        align="center"
        textTransform="uppercase"
        color="#003478"
      >
        INTEGRITY IS EVERYTHING
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Typography variant="h5" color="text.secondary">
      Ultimate Drafting Services assure the integrity of our services and the completion of all projects. 
      Our team of professionals shares the experience of working with projects of all sizes, ensuring a sharp level of accuracy and consistency with all our clients. 
      We deliver plans at the highest quality and convenience, suitable for any budget.
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);
export const MIntegrity = (
  <React.Fragment>
    <CardContent>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        align="center"
        textTransform="uppercase"
        color="#003478"
      >
        INTEGRITY IS EVERYTHING
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Typography variant="body2" color="text.secondary">
      Ultimate Drafting Services assure the integrity of our services and the completion of all projects. 
      Our team of professionals shares the experience of working with projects of all sizes, ensuring a sharp level of accuracy and consistency with all our clients. 
      We deliver plans at the highest quality and convenience, suitable for any budget.
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);


export const OURSTORY = (
  <React.Fragment>
    <CardContent>
      <Typography
        variant="h3"
        textAlign="right"
        color="#003478"
      >
        OUR STORY
      </Typography>
      <Typography variant="h5" color="text.secondary" textAlign="right">
      Our CEO, Ricardo Maciel, started this company in 2010 as a small project. 
      After 12 years of service, Ultimate Drafting Services has evolved into a more extensive operation capable of handling multiple projects, ranging from fire damage repairs and kitchen remodels to tenant improvement and developing a new home. 
      We continue to serve our clients with integrity, professionalism, and determination to complete any project.
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);

export const MOURSTORY = (
  <React.Fragment>
    <CardContent>
      <Typography
        variant="h6"
        textAlign="center"
        color="#003478"
      >
        OUR STORY
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="left">
      Our CEO, Ricardo Maciel, started this company in 2010 as a small project. 
      After 12 years of service, Ultimate Drafting Services has evolved into a more extensive operation capable of handling multiple projects, ranging from fire damage repairs and kitchen remodels to tenant improvement and developing a new home. 
      We continue to serve our clients with integrity, professionalism, and determination to complete any project.
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);

export const Values = (
  <React.Fragment>
    <CardContent>
      <Typography

        variant="h3"
        align="center"
        color="#003478"
      >
        VALUES THAT MAKES US DIFFERENT
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);


export const MValues = (
  <React.Fragment>
    <CardContent>
      <Typography

        variant="h6"
        align="center"
        color="#003478"
      >
        VALUES THAT MAKES US DIFFERENT
      </Typography>
      <Box
        component="span"
        sx={{
          display: "block",
          p: 1
        }}
      />
    </CardContent>
  </React.Fragment>
);

export const IMG = (
  <React.Fragment>
    <CardMedia component="img"  image={IMGCON} alt="Media" />
  </React.Fragment>
);

export const MIMG = (
  <React.Fragment>
    <CardMedia component="img" image={IMGCON} alt="Media" />
  </React.Fragment>
);

export const MDIMG = (
  <React.Fragment>
    <CardMedia component="img"  image={IMGCON} alt="Media" />
  </React.Fragment>
);
