import React, { FormEvent , useState, useRef ,ChangeEvent} from 'react';
import {styled} from '@mui/material/styles';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


type FormState = {
  email: string;
  name: string;
  message: string;
};

type ServiceMessage = {
  class: string;
  text: string;
};

const Title = styled(Typography)(({ theme }) => ({
  
  color:"#003478",
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 23,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 26,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 26,
  },
}));

const SbuTitle = styled(Typography)(({ theme }) => ({
  color: "text.secondary" ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 14,
    Blob:3,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 27,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 27,
  },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Form(){

  const formId = "lHGFSpPD";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6Ld34aweAAAAAEe65ed_3URevuE6BSuw0dJ1SdBt';
  const recaptchaRef = useRef<any>();

  
  const initialFormState = {
    email: "",
    name: "",
    message: "",
  };
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();



  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
    ...formState,
    "g-recaptcha-response": recaptchaToken,
    };

    try{
      const result = await axios.post(formSparkUrl, payload)
      console.log(result);
      setMessage({
        class: "bg-green-500",
        text: "Thanks, someone will be in touch shortly.",
        
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    }catch(error){
      console.log(error);
      setMessage({
        class: "bg-red-500",
        text: "Sorry, there was a problem. Please try again or contact support.",
      });

    }
  };
  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  return(
    <React.Fragment>
      <Box m="auto" >
      
      <Container maxWidth="xl">
        
        <Card elevation={0}> 
        <CardContent> 
        <Grid container >
        
        {message && (
          <Snackbar autoHideDuration={6000} >
          <Alert className={`my-4 text-white w-full p-4 ${message.class}`}>
            {message.text}
          </Alert>
          </Snackbar>
        )}
        <form onSubmit={submitForm}>
          <Grid item xs={12} >

          <CardContent>
        <Title textTransform="uppercase">
          Tell Us About Your Project
        </Title>
        <Box
          component="span"
          sx={{
            display: "block",
            p: 1
          }}
        />
        <SbuTitle >
        Get in touch with a member from our team of professionals and schedule a consultation. 
        We are available and more than happy to work with you to turn your project into a reality.
        </SbuTitle>
        <Box
          component="span"
          sx={{
            display: "block",
            p: 1
          }}
        />
        </CardContent>

            <TextField
              onChange={updateFormControl}
              className="border-2 p-2"
              type="text"
              id="name"
              label="Full Name"
              fullWidth
              value={formState?.name}
            />
          </Grid>
          <Grid item xs={12}> 
            <TextField
              onChange={updateFormControl}
              className="border-2 p-2"
              type="email"
              label="Email"
              id="email"
              fullWidth
              value={formState?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={updateFormControl}
              className="border-2 p-2"
              id="message"
              label="Message"
              multiline
              rows={4}
              fullWidth
              value={formState?.message}
            ></TextField>
          </Grid>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={updateRecaptchaToken}
          />
        <button
        disabled={submitting}
            className="mt-4 my-2 bg-blue-700 text-white w-full p-2 hover:bg-blue-900 transition-colors duration-200"
          >
            {submitting ? "Submitting..." : "Submit"}
        </button>
        </form>
        </Grid>
        </CardContent>
        </Card>
        </Container>
        </Box>
        </React.Fragment>
  );
}

export default Form;


