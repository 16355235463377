import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import Image from "../../Assets/IMAGES/Index/Hero.jpg";
import Grow from "@mui/material/Grow";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




const H1 = styled(Typography)(({ theme }) => ({
  color: 'white',
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 32,
    
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 60,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 90,
  },
}));

const H2 = styled(Typography)(({ theme }) => ({
  color: 'white',
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 20,
    
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 34,
  },
}));

export default function Hero() {

const [shouldShow, setShouldShow] = React.useState(false);

useEffect(() => setShouldShow(true),[]);
return (
  <>
  <Box sx={{ display: { xs: 'none',sm: 'Block', md: 'Block', lg: 'Block', xl: 'Block'}}}>  
    <Paper sx={{height: "98vh",
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center" }}>
      <Container sx={{height: "100%"}} >
          <Grid
          sx={{height: "100%",
          zIndex: 100,
          position: "relative"}} 
          container
          justifyContent="space-around"
          alignItems="center"
          textAlign= "center" 
          >
            <Grow
                in={shouldShow}
                style={{ transformOrigin: "0 0 0" }}
                {...(shouldShow ? { timeout: 2000 } : {})}
            >
                <Grid item sm={12}>
                    <H1 >
                    Ultimate Drafting
                    </H1>
                    <H2 >
                    Integrity Strengthens Design
                    </H2>
                </Grid>
            </Grow>
          </Grid>
      </Container>
    </Paper>
    </Box>
  <Box sx={{ display: { xs: 'Block',sm: 'none', md: 'none', lg: 'none', xl: 'none'}}}>  
    <Paper sx={{height: "60vh",
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center" }}>
      <Container sx={{height: "100%"}} >
          <Grid
          sx={{height: "100%",
          zIndex: 100,
          position: "relative"}} 
          container
          justifyContent="space-around"
          alignItems="center"
          textAlign= "center" 
          >
            <Grow
                in={shouldShow}
                style={{ transformOrigin: "0 0 0" }}
                {...(shouldShow ? { timeout: 2000 } : {})}
            >
                <Grid item sm={12}>
                    <H1 gutterBottom>
                    Ultimate Drafting
                    </H1>
                    <br/>
                    <H2 gutterBottom>
                    Integrity Strengthens Design
                    </H2>
                </Grid>
            </Grow>
          </Grid>
      </Container>
    </Paper>
    </Box>

    </>
);
}
