import * as React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import Box from "@mui/material/Box";

const Title = styled(Typography)(({ theme }) => ({
  color:"#003478",
  textAlign: 'center' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 34,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 48,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 48,
  },
  
}));

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'left' ,
  Typography: theme.spacing(1),
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 13,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 28,
  },
}));



export default function Processinfo() {
  return (
    <Container maxWidth ="xl">
      <Box m="auto" >
      <Stack p={5} spacing={3}>
        <Title color="#003478" >
          OUR PROCESS
        </Title>
        <Description>
        We take pride in the integrity and professionalism behind every project and service we provide. 
        The creative process from initial meeting to project implementation ensures that we meet your every needs.
        </Description>
      </Stack>
      </Box>
    </Container>
    
);
}
